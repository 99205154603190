'use client';


import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

// @ts-ignore
import ConvertKitForm from 'convertkit-react';

import { Config } from "@/models/footer";
import { useCursorEffects } from "../cursor/useCursorEffects";
import { Contact } from "@/models/contact";
import { getSocialMediaIconClass } from "@/shared/util";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    config: Config,
    contact: Contact
}

export default function Footer({ config, contact }: Props) {  
    const container = useRef<HTMLDivElement>(null);
    const tl = useRef<gsap.core.Timeline>();
    const [emailConfig, setEmailConfig] = useState<{[key:string]: any}|null>(null);

    const year = new Date().getFullYear();

    const isDev = process.env.NODE_ENV === 'development';

    useCursorEffects(container, { color: "tertiary" });

    useEffect(() => {
        setEmailConfig({
            formId: parseInt(config.email_form_id),
            template: 'clare',
            submitText: 'I volunteer my inbox',
            buttonBackground: "#F7FF07",
            buttonColor: "#3E3E3D",
            hideName: true,
            namePlaceholder: "Name",
            emailPlaceholder: "Email"
        });
    }, [config]);

    useGSAP(() => {

        // const main = document.body.querySelector("main");
        // const footerRect = container.current?.getBoundingClientRect();

        // tl.current = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: main,
        //         start: 'bottom bottom',
        //         end: footerRect?.height
        //     }
        // });

        // gsap.set(container.current, { autoAlpha: 0 });

        // tl.current.to(container.current, { autoAlpha: 1 });
            
    }, []);


    return (
        <>
            <div id="footer-push"></div>
            <footer ref={container} className="bg-primary left-0 right-0 bottom-0 overflow-hidden text-lg text-white w-full">
                <div  className="container">
                    <div className="flex flex-col justify-end h-full pb-8">
                        <div className="py-16">
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                <div className="logo-type hidden md:block">
                                    <span>Three</span>
                                    <span>Two</span>
                                    <span>Eight</span>
                                    <span>Studios</span>
                                </div>
                                <div>
                                    {emailConfig &&
                                        <>
                                            <div className="text-3xl font-bold mb-8 text-center">{config.email_cta}</div>
                                            {!isDev &&
                                            <ConvertKitForm {...emailConfig} />
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                            <div className="space-y-4 col-span-2 md:col-span-1 order-last md:order-first text-center md:text-left">
                                <div>
                                    A branding and design studio for the audacious and ambitious.
                                </div>
                                <div>
                                    <div>328 Studios LLC</div>
                                    <div>Copyright © {year}</div>
                                </div>
                            </div>
                            {config.menus.map((menu) =>
                                <div key={menu.handle}>
                                    <div className="font-bold">{menu.title}</div>
                                    <ul>
                                        {menu?.links.map((link) =>
                                            <li key={link.page.id}><Link href={link.page.url} target={link.page.external ? '_blank' : '_self'} className={`${link.page.external ? 'external-link' : ''}`}>{link.page.title}</Link></li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div>
                                <div className="font-bold">Contact</div>
                                <div><a href={`tel:${contact.phone_number}`}>{contact.phone_number}</a></div>
                                <div><a href={`mailto:${contact.email}`}>{contact.email}</a></div>
                                <ul className="flex items-center space-x-2">
                                    {config.social_media?.links.map((link, index) =>
                                        <li key={link.page.id}>
                                            <Link href={link.page.url} className="text-lg" aria-label={link.page.title} target="_blank">
                                                <i className={`${index == 0 ? 'fa-brands' : 'fa-brands'} fa-${getSocialMediaIconClass(link.page.title)}`} aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}