"use client";

import { useEffect, useState } from 'react';
import Image from 'next/image';
import Container from '@/components/core/container';
import './header.css';
import MenuTrigger from '../menu/trigger';
import StartTriggerButton from '@/modules/start/button';
import Logo from '../logo';
import { NavigationState, useNavigation } from '../provider';

export default function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      handleScroll();

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const { visible } = useNavigation();

  return (
    <header className={`fixed w-full z-40 top-0 text-slate-900 ${scrollPosition < 15 ? 'nav-expand' : 'nav-minimize'} ${visible != NavigationState.CLOSED ? 'nav-hide' : ''}`} >
      <Container className="flex items-center justify-between w-full h-full py-2">
        <a id='logo' href="/" className="logo block">
          <Logo />
        </a>
        <div className='flex items-center space-x-4'>
          <StartTriggerButton />
          <MenuTrigger />
        </div>
      </Container>
    </header>
  )
}